import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type IDialogProps = {
  isDialogOpened: boolean,
  dialogText: string,
  doAfter: () => void,
  onClose: (confirmed: boolean) => void
};

export default function ConfirmationDialog(props: IDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleCloseYes = () => { props?.onClose(true); 
                                 props.doAfter(); };
  const handleCloseNo = () => props?.onClose(false);
  

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.isDialogOpened}
        onClose={handleCloseNo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={handleCloseNo}>
            No
          </Button>
          <Button variant='outlined' onClick={handleCloseYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
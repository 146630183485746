import * as React from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Item } from '../styles';

interface ModalProps {
  isLoading: boolean;
}

const ProgressControl: React.FC<ModalProps> = ({ isLoading }) => {
  return (
    <Modal
      aria-labelledby='loading-modal-title'
      open={isLoading}
      disableEscapeKeyDown
      component="div">
      <Item style={{
        padding: '3em',
        top: '50%',
        left: '50%',
        marginTop: '-10em',
        marginLeft: '-7em',
        position: 'fixed',
        backgroundColor: '#d5f5f5',
        zIndex: '999',
      }}><CircularProgress size={100} /></Item>
    </Modal>
  );
}
export default ProgressControl;



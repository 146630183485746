import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

import '../../App.css';
import { Item, Item2 } from '../../styles';
import AppSettings from '../../API/appSettings';
import { getSession } from '../../services/Login';
import Header from '../../components/HeaderComponent';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import RefreshError from '../../schemas/Exception/RefreshError';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import HelpIconWithHint from '../../components/HelpIconWithHint'
import { fetchGet, fetchPost } from '../../services/GettingData';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IExactSearchResponse from '../../schemas/Compound/IExactSearchResponse';
import CompoundPropertiesComponent from '../../components/Compound/CompoundPropertiesComponent';
import CompoundSpectrumComponent from '../../components/Compound/CompoundSpectrumComponent';
import PublicationsComponent from '../../components/Publication/PublicationsComponent';

export type ExactSearchProps = { svgContent: string, moleculeString: string };
type ExactSearchState = {
  isLoading: boolean,
  isMoleculeInContainer: boolean,
  compoundSearchResponse: IExactSearchResponse,
};


class ExactSearchPage extends React.PureComponent<ExactSearchProps, ExactSearchState> {
  constructor(props: { svgContent: string, moleculeString: string }) {
    super(props);
    this.state = {
      isLoading: true,
      compoundSearchResponse: {
        id: '',
        svg: (props as any).location?.state?.svgContent ? (props as any).location?.state?.svgContent : '',
        molecule: '',
        properties: {},
        total_publications: 0,
        associated_spectra: []
      },
      isMoleculeInContainer: false,
    };
  }


  async componentDidMount(): Promise<void> {
    if ((this.props as any).match.params['molid'])
      this.setState({
        isLoading: false,
        compoundSearchResponse: await this.getCompound((this.props as any).match.params['molid'])
      });
    else
      await this.searchExact();
  }


  async getCompound(moleculeId: number): Promise<IExactSearchResponse> {
    this.setState({ isLoading: true });
    const response = await fetchGet('/compound/id/' + moleculeId);
    return await response.json();
  }
 

  async searchExact(): Promise<void> {
    let molecule = this.getMolecule();
    if (!molecule) {
      console.warn('no molecule');
      (this.props as any).history.push('/compound-search');
    }
    this.setState({ isLoading: true });
    let compoundSearchResponse;
    try {
      const response = await fetchPost('/compound/search/exact', JSON.stringify(molecule), true, true);
      if (response.ok) {
        compoundSearchResponse = await response.json();
        if (!compoundSearchResponse) compoundSearchResponse = {};
      }
      else {
        if (response.status === 404)
          compoundSearchResponse = {};
      }
    }
    catch (e: any) {
      this.setState({ isLoading: false, compoundSearchResponse: compoundSearchResponse });
      if (e instanceof ExpiredAccountError)
        (this.props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });

      if (e instanceof RefreshError) {
        alert(e.message);
        (this.props as any).history.push({ pathname: '/login', state: { welcomeMessage: true } });
      }

      if (e instanceof TimeoutError) {
        alert(e.message);
      }
      else
        alert(e.toString());
    }


    this.setState({
      isLoading: false,
      compoundSearchResponse: compoundSearchResponse,
    });
  }


  getMolecule(): string {
    return (this.props as any).location?.state?.moleculeString;
  }


  render() {
    return (this.state.compoundSearchResponse ?
      <Grid container md={12} spacing={0} className='main-frame'>
        <ProgressControl isLoading={this.state.isLoading} />
        <Grid md={12}>
          <Item2><div style={{ height: '2em' }}></div></Item2>
        </Grid>

        <Grid container xs={12}>
          <Header title='Exact Search' showLogin={getSession()!==undefined} helpAddress='help#wics'/>
        </Grid> 

        <Grid container xs={12}>
          <Grid container md={8} xs={12} style={{ display: 'flex' }}>
            <Grid xs={12} container>
              <Grid xs={12} container>
                <Grid xs={12} md={4}></Grid>
                <Grid md={8} xs={12} container>
                  <Grid xs={12} container style={{ height: '100%' }}>
                    <Grid md={6} xs={12} className='ab' >
                      <Item2 className='aa'>
                        <Tooltip arrow title={'start new search'}>
                          <Button onClick={() => {
                            localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN);
                            (this.props as any).history.push('/editor/main');
                          }} variant="contained">New Search</Button>
                        </Tooltip>
                        <HelpIconWithHint title={''}
                          text={'Drop results and draw new query'}
                          handleOpen={() => { }} />
                      </Item2>
                    </Grid>
                    <Grid md={6} xs={12} className='ab' >
                      <Item2 className='aa'>
                        <Link to='/editor/main'>
                          <Tooltip arrow title='modify query'>
                            <Button variant="contained" disabled={false} >Edit Search</Button>
                          </Tooltip>
                        </Link>
                        <HelpIconWithHint title={''}
                          text={'Drop results and modify the query'}
                          handleOpen={() => { }} />
                      </Item2>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid md={4} xs={12}>
            <Item className='center-xs' style={{ width: '250px', marginLeft: 'auto' }}>
              <MoleculeView svgContent={this.state.compoundSearchResponse.id ? this.state.compoundSearchResponse?.svg : (this.props as any).location?.state?.svgContent} link={''} isMoleculeInContainer={true} />
            </Item>
          </Grid>
        </Grid>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Item id='block-stat'>
                <div className='molecules-count'>Found {this.state?.compoundSearchResponse.id ?
                  1 : 0} molecules</div>
              </Item>
            </Grid>

            <Grid xs={12} spacing={1}>
              {this.state.compoundSearchResponse.id &&
                <Item style={{ fontSize: '1em', paddingTop: '0px', paddingBottom: '0px', marginTop: '2em', textAlign: 'left', padding: '1em' }}>
                  <Box sx={{ minHeight: '2em', width: '100%' }}>
                    <CompoundSpectrumComponent svgContent={this.state.compoundSearchResponse?.svg}
                      compoundId={this.state.compoundSearchResponse.id}
                      spectra={this.state.compoundSearchResponse.associated_spectra} />
                  </Box>
                </Item>
              }
            </Grid>

            <Grid xs={12} spacing={1}>
              {this.state.compoundSearchResponse.id &&
                <Item style={{
                  fontSize: '1em',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  marginTop: '2em',
                  textAlign: 'left',
                  padding: '1em'
                }}>
                  <Box sx={{ minHeight: '2em', width: '100%' }}>
                    <CompoundPropertiesComponent compoundId={this.state.compoundSearchResponse.id}
                      propertiesCount={this.state.compoundSearchResponse?.properties} />
                  </Box>
                </Item>
              }
            </Grid>
            <Grid xs={12} style={{ marginBottom: '1em' }}>
              {this.state.compoundSearchResponse.id &&
                <Item style={{ fontSize: '1em', paddingTop: '0px', paddingBottom: '0px', marginTop: '2em', textAlign: 'left', padding: '1em' }}>
                  <Box sx={{ minHeight: '2em', width: '100%' }}>
                    <div><span>Associated publications </span>{'(' + this.state.compoundSearchResponse?.total_publications + ')'}</div>
                    <PublicationsComponent compoundId={this.state.compoundSearchResponse.id} />
                  </Box>
                </Item>
              }
            </Grid>
            <Grid xs={12}>
              <div></div>
            </Grid>
          </Grid>
      </Grid> : ''
    );
  }
}
export default ExactSearchPage;
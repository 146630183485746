import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2'
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import Pagination from '@mui/material/Pagination';
import { Tooltip } from '@mui/material';

import { Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ProgressControl from '../../components/ProgressControl';
import ICombineQuery from '../../schemas/History/ICombineQuery';
import { fetchPost } from '../../services/GettingData';
import { ICombineResponse } from '../../schemas/History/ICombineResponse';
import { transformJsonToAcs } from '../../services/Publication'; 


type HistoryOperationProp = {
  location: {
    state: ICombineQuery
  }
};

export const PublicationHistoryPage: React.FC<HistoryOperationProp> = (props) => {
  const [combineResponse, setCombineResponse] = useState<ICombineResponse>();
  const [combineQuery, setCombineQuery] = useState<ICombineQuery>({ ...props.location.state, page: 1, size: 10 });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const theme = useTheme();
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  const combine = async (query: ICombineQuery) => {
    setIsLoading(true);
    const response = await fetchPost('/search/combine/' + query.method + '/' + query.by + '?page=' + query.page + '&size=' + query.size,
      JSON.stringify(combineQuery.queries_ids), true, true);
    if (response.ok) {
      setCombineResponse(await response.json() as ICombineResponse);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      throw ('no data');
    }
  }

  const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
    setCombineQuery({ ...combineQuery, page: value });
  };

  useEffect(() => {
    combine(combineQuery);
  }, [combineQuery]);


  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Header title={combineQuery.method === 'union' ? 'Search Union' : 'Search Intersection'} showLogin={true} />
      <Grid container xs={12}>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
      </Grid>

      <Grid container xs={12}>
        <Grid md={1}></Grid>
        <Grid md={5} style={{ fontSize: '1.2em', fontWeight: '600', marginBottom: '1em' }}>
          Found {combineResponse?.total} publications
        </Grid>
        <Grid md={5}>
        </Grid>
        <Grid md={1}></Grid>
      </Grid>
      {combineResponse?.items.map((elem, index) => (
        <Grid container xs={12} style={{ fontSize: '1.5em' }} key={index}>
          <Grid xs={0} md={1}></Grid>
          <Grid sm={12} md={5} style={{
            padding: '0.5em',
            fontWeight: '600',
            borderColor: 'lightgray',
            borderTopLeftRadius: index === 0 ? '3px' : '0px',
            borderBottomLeftRadius: '3px',
            borderCollapse: 'separate',
            borderWidth: index === 0 ? '1px' : '0px 1px 1px 1px',
            borderRightWidth: isMdOrLarger ? '0px' : '1px',
            borderStyle: 'solid'
          }}>
            <Grid xs={12} container>
              <Grid xs={8} style={{ textAlign: 'left' }}>
                <div style={{padding:'.5em'}} dangerouslySetInnerHTML={{__html:transformJsonToAcs(elem.publication)}}></div>
                {elem.id}
              </Grid>
              <Grid xs={4} style={{ textAlign: 'right', flexGrow: 1 }}>
                <a href={'/publication-search?publicationId=' + elem.publication_id} target='_blank'>
                  <Tooltip title="See compounds and spectra described in the paper">
                    <AutoStoriesRoundedIcon style={{ fontSize: '1.5em' }} color="primary" />
                  </Tooltip>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid sm={12} md={5} style={{
            padding: '0.5em',
            fontWeight: '600',
            borderColor: 'lightgray',
            borderTopRightRadius: index === 0 ? '3px' : '0px',
            borderBottomRightRadius: '3px',
            borderCollapse: 'separate',
            borderWidth: index === 0 ? '1px' : '0px 1px 1px 1px',
            borderStyle: 'solid',
            fontSize: '1em'
          }}>
            <div>Compounds found: {elem.n_compounds}</div>
            {elem.spectra_counts && Object.keys(elem.spectra_counts).map((key, i) => {
              return (<div key={i}>
                {key.replace('_', ' ')} spectra: {elem.spectra_counts[key]}
              </div>);
            })}
          </Grid>
          <Grid sm={0} md={1}></Grid>
        </Grid>
      ))}

      {!isLoading && combineResponse && combineResponse?.items.length > 0 &&
        <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
          <Pagination style={{ marginTop: '1em' }}
            count={Math.ceil((combineResponse ? combineResponse.total : 0) /
              (combineResponse ? combineResponse.size : 10))}
            page={combineResponse?.page} onChange={(e, v) => pageChangeHandle(e, v)} />
        </Grid>}
    </Grid >
  );
}
export default PublicationHistoryPage;
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2'
import Checkbox from '@mui/material/Checkbox';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';

import { Item, Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import { molToSvg } from '../../services/NameToMolecule';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog'; 
import {
  byCompound,
  byPublication,
  intersectionByCompounds,
  intersectionByPublications,
  unionByCompounds,
  unionByPublications
} from '../../schemas/History/IOperationType';
import IQueryHistoryModel from '../../schemas/History/IQueryHistoryModel';
import NotAuthorizedError from "../../schemas/Exception/NotAuthorizedError";
import IQueryHistoryResponse from '../../schemas/History/IQueryHistoryResponse';
import { fetchGet, fetchDelete } from '../../services/GettingData';

export const HistoryPage: React.FC = (props: any) => {

  const [items, setItems] = useState<IQueryHistoryModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedHistoryItems, setSelectedHistoryItems] = useState<string[]>([]);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [renderHistoryItems, setRenderHistoryItems] = React.useState<React.JSX.Element[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = React.useState<boolean>(false);
  const size = 10;
  
  const maxPageRef = React.useRef(maxPage);
  const pageRef = React.useRef(page);

  useEffect(() => {
    maxPageRef.current = maxPage;
  }, [maxPage]);


  useEffect(() => {
    pageRef.current = page;
  }, [page]);


  const historyItems = (historyItemId: string) => {
    if (selectedHistoryItems.includes(historyItemId)) {
      setSelectedHistoryItems(selectedHistoryItems.filter(item => item != historyItemId));
    }
    else
      setSelectedHistoryItems([...selectedHistoryItems, historyItemId]);
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  }

  
  const onCloseConfirmationDialog = () =>
    setConfirmationDialogOpened(false);


  const checkScrollPosition = () => {
    if (maxPageRef.current == 0  || maxPageRef.current < pageRef.current)
      return; 

    if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight * 0.9 )
      return;
    setPage((prevPage) => prevPage + 1);
  };


  useEffect(() => {
    console.log('addEventListener scroll');
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  
  const toOperation = (method: string, by: string) => {
    if (selectedHistoryItems.length !== 2) {
      showError('2 items should be selected');
      return;
    }
 
    
    let leftOperand = items!.filter(item => item.query_id === selectedHistoryItems[0])[0];
    let rightOperand = items!.filter(item => item.query_id === selectedHistoryItems[1])[0];
    
    if (by === byPublication)
      (props as any).history.push({
        pathname: '/publication-history',
        state: {
          queries_ids: [leftOperand.query_id, rightOperand.query_id],
          by: by,
          method: method,
        }
      });

    if (by === byCompound)
      (props as any).history.push({
        pathname: '/compound-history',
        state: {
          historyPreview: {
            queries_ids: [leftOperand.query_id, rightOperand.query_id],
            by: byCompound,
            method: method,
          }
        }
    });
  }


  useEffect(() => {
    const fetchData = async () => {
      if (maxPageRef.current !==0 && maxPageRef.current < pageRef.current) { 
        return; 
      }
      
      try {
        const response = await fetchGet('/user/history/queries/list?page=' + page + '&size=' + size, false, true);
        if (response.ok) {
          const entity = await response.json() as IQueryHistoryResponse;
          setMaxPage(entity.pages!);
          setItems(prev=>([...prev, ...entity.items]));
        }
        else {
          setIsLoading(false);
          throw('no data');
        }
        setIsLoading(false);
      }
      catch (e: any) {
        console.error('exception', e);
        setIsLoading(false);

        if (e instanceof NotAuthorizedError) {
          (props as any).history.push({ pathname: '/login', state: { backTo: '/search-history', welcomeMessage: false } })
        }
      }
    }
    fetchData().catch(console.error);
  }, [size, page]);


  const closeErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const cleanHistory = async () => {
    setIsLoading(true);
    const response = await fetchDelete('/user/history/queries/delete', false, true);
    if (response.ok)
      setIsLoading(false);
  }


  useEffect(() => {
    const fetchItem = async () => {
      if (items) {
        const sortedItems = items.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        const renderHistoryItems_ = await Promise.all(sortedItems.map(async mol => renderItemHistorySearch(mol)) ?? []);
        setRenderHistoryItems(renderHistoryItems_);
      }
    };
    fetchItem();
  }, [items, selectedHistoryItems]);


  const renderHistorySearchItem = (query_id: string,
    moleculesTotal: number | undefined,
    publicationsTotal: number | undefined,
    spectraTotal: number | undefined,
    created_at: Date, title: string,
    svg: string | undefined = undefined,
    search_page: string | undefined = undefined,
    props: any | undefined = undefined) => {
    return (
      <Grid key={query_id} container xs={12} style={{ margin: '.5em' }}>
        <Grid xs={2}>
          <Checkbox style={{ transform: "scale(2)" }}
            onClick={() => historyItems(query_id)} />
        </Grid>
        <Grid xs={3} style={{ textAlign: 'left' }}>
          <Link to={{
            pathname: search_page,
            state: props
          }}>
            <div style={{ fontSize: '1.5em', fontWeight: '600' }}>{title}</div>
            {moleculesTotal !== undefined && <div style={{ fontSize: '1.3em' }}>
              <span style={{ textAlign: 'left', marginRight: '.5em' }}>{moleculesTotal}</span>molecules
            </div>}
            {publicationsTotal !== undefined &&
              <div style={{ fontSize: '1.3em' }}>
                <span style={{ textAlign: 'left', marginRight: '.5em' }}>{publicationsTotal}</span>publications
              </div>}
            {spectraTotal !== undefined &&
              <div style={{ fontSize: '1.3em' }}>
                <span style={{ textAlign: 'left', marginRight: '.5em' }}>{spectraTotal}</span>spectra
              </div>}
          </Link>

        </Grid>
        <Grid xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
          {svg !== undefined &&
            <MoleculeView svgContent={svg} mini={true} inline={true} link={''} isMoleculeInContainer={true} />
          }
        </Grid>
        <Grid xs={3}>
          <div style={{ fontSize: '1.5em', fontWeight: '600' }}>
            {formatDate(created_at)}
          </div></Grid>
      </Grid>);
  }


  const formatDate = (date: Date) => {
    const reTime = /(\d+\-\d+\-\d+)\D(\d+\:\d+\:\d+)(\.\d+)/;
    var newTime = date.toLocaleString().replace(reTime, '$1');
    return newTime;
  }


  const capitalizeFirstLetter = (str: string | undefined) => {
    return str ? str?.charAt(0).toUpperCase() + str?.slice(1) : '';
  }


  const renderItemHistorySearch = async (queryHistoryModel: IQueryHistoryModel) => {
    if (queryHistoryModel.query_type === 'publication')
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        undefined,
        1,
        undefined,
        queryHistoryModel.created_at,
        'Publication search',
        undefined,
        '/publication-search',
        { 'publicationId': queryHistoryModel.query.publication_id } as any
      ));
    if (queryHistoryModel.query_type === 'spectrum') {
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        queryHistoryModel.result_preview.molecules.total,
        queryHistoryModel.result_preview.publications.total,
        queryHistoryModel.result_preview.spectra.total,
        queryHistoryModel.created_at,
        queryHistoryModel.query.spectrum_type!.replace('_', ' ') + ' spectrum search',
        undefined,
        '/spectrum-search-result/1',
        {
          'spectrumPreview': {
            peaks: queryHistoryModel.query.peaks,
            spectrum_type: queryHistoryModel.query.spectrum_type,
            solvent: queryHistoryModel.query.solvent,
            structure: queryHistoryModel.query.structure,
            moleculeWeightFilters: queryHistoryModel.query.weights,
            mandatoryPeaks: queryHistoryModel.query.mandatory_peaks,
          }
        }));
    }
    if (queryHistoryModel.query_type === 'compound_exact')
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        queryHistoryModel.result_preview.molecules.total,
        queryHistoryModel.result_preview.publications.total,
        undefined,
        queryHistoryModel.created_at,
        'Compound search',
        queryHistoryModel.result_preview.svg,
        '/exact-search/',
        {
          'svgContent': queryHistoryModel.result_preview.svg,
          'moleculeString': queryHistoryModel.query.structure,
        }
      ));
    if (queryHistoryModel.query_type === 'compound_sub') {
      const optionalSvgs = await Promise.all(queryHistoryModel.query?.optional_mols?.map(async mol => await molToSvg(mol)) ?? []);
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        queryHistoryModel.result_preview.molecules.total,
        queryHistoryModel.result_preview.publications.total,
        undefined,
        queryHistoryModel.created_at,
        'Compound search',
        queryHistoryModel.result_preview.svg,
        '/sub-search/1',
        {
          'svgContent': queryHistoryModel.result_preview.svg,
          'moleculeString': queryHistoryModel.query.mol_query,
          'subSearchFilterModel': {
            'mol_query': queryHistoryModel.query.mol_query,
            'from_search_id': queryHistoryModel.query.from_search_id ? queryHistoryModel.query.from_search_id : undefined,
            'compound_props': queryHistoryModel.query.compound_props,
            'nmr_types': queryHistoryModel.query.nmr_types,
            'optional_mols': queryHistoryModel.query.optional_mols,
            'iterate_tautomers': queryHistoryModel.query.iterate_tautomers,
            'show_radicals': queryHistoryModel.query.show_radicals,
            'optional_svgs': optionalSvgs,
          }
        }
      ));
    }
    if (queryHistoryModel.query_type === 'combine' && queryHistoryModel.query.by_entity === byPublication) {
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        undefined,
        queryHistoryModel.result_preview.total,
        undefined,
        queryHistoryModel.created_at,
        capitalizeFirstLetter(queryHistoryModel.query.mode) + ' by ' + capitalizeFirstLetter(queryHistoryModel.query.by_entity),
        undefined,
        '/publication-history',
        {
          queries_ids: queryHistoryModel.query.ids,
          by: queryHistoryModel.query.by_entity,
          method: queryHistoryModel.query.mode,
        }));
    }
    if (queryHistoryModel.query_type === 'combine' && queryHistoryModel.query.by_entity === byCompound) {
      return (renderHistorySearchItem(queryHistoryModel.query_id,
        queryHistoryModel.result_preview.molecules.total,
        queryHistoryModel.result_preview.publications.total,
        undefined,
        queryHistoryModel.created_at,
        capitalizeFirstLetter(queryHistoryModel.query.mode) + ' by ' + capitalizeFirstLetter(queryHistoryModel.query.by_entity),
        undefined,
        '/compound-history',
        {historyPreview: {
          queries_ids: queryHistoryModel.query.ids,
          by: queryHistoryModel.query.by_entity,
          method: queryHistoryModel.query.mode,
        }}));
    }
    return (<>Unknown search</>);
  }


  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <ConfirmationDialog dialogText={'Do you want to clean up the History ?'} 
                          doAfter={cleanHistory} 
                          isDialogOpened={isConfirmationDialogOpened}
                          onClose={onCloseConfirmationDialog} />

      <ErrorDialog isDialogOpened={isErrorDialogOpened}
        errorMessage={errorMessage}
        warningMessage={''}
        onClose={closeErrorDialog} />
      <Header title='History of Search' showLogin={true} />
      <Grid container xs={12}>
        <Grid md={2} style={{ marginTop: '4em', padding: '1em', minWidth: '310px' }}>
          <Item className='history-item'>
            <div style={{ cursor: 'pointer' }} onClick={() => toOperation(intersectionByCompounds.method,
              intersectionByCompounds.by)}>
              <div>Intersection by compounds</div>
              <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
            </div>
          </Item>

          <Item className='history-item'>
            <div style={{ cursor: 'pointer' }} onClick={() => toOperation(unionByCompounds.method, unionByCompounds.by)}>
              <div>Union by compounds</div>
              <div><JoinFullIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
            </div>
          </Item>

          <Item className='history-item'>
            <div style={{ cursor: 'pointer' }} onClick={() => toOperation(intersectionByPublications.method, intersectionByPublications.by)}>
              <div>Intersection by publications</div>
              <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
            </div>
          </Item>

          <Item className='history-item'>
            <div style={{ cursor: 'pointer' }} onClick={() => toOperation(unionByPublications.method, unionByPublications.by)}>
              <div>Union by publication</div>
              <div><JoinFullIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
            </div>
          </Item>

          <Item className='history-item'>
            <div style={{ cursor: 'pointer' }} onClick={() => {setConfirmationDialogOpened(true)}}>
              <div>Clean the History</div>
              <div></div>
            </div>
          </Item>
        </Grid>

        <Grid md={8} xs={12} style={{ marginTop: '4em', padding: '1em' }}>
          {renderHistoryItems?.map((elem, index) => (
            <Item key={index} style={{ marginBottom: '1em' }}>
              {elem}
            </Item>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HistoryPage;

import { fetchGet, fetchPost } from './GettingData';
import ISpectrumPreviewModel from '../schemas/Spectrum/ISpectrumPreviewModel';
import ISpectraModel from '../schemas/Spectrum/ISpectraModel';


async function getPreview(spectrumPreviewModel: ISpectrumPreviewModel): Promise<ISpectrumPreviewModel> {
  const response = await fetchPost('/spectrum/preview/peaks', 
                                JSON.stringify({'spectrum_type': spectrumPreviewModel.spectrum_type,
                                                'peaks': spectrumPreviewModel.peaks,
                                               }), true, true); 
  const spectrumPreview = await response.json();
  spectrumPreview.solvent = spectrumPreviewModel.solvent; 
  spectrumPreview.spectrumString = spectrumPreviewModel.spectrumString;
  spectrumPreview.searchDirection = spectrumPreviewModel.searchDirection;
  return spectrumPreview;
}

function getReactionMixtures() {
  return {
    'EXACT_MODE': 'EXACT MODE',
    'REACTION_MIXTURE_MODE': 'REACTION MIXTURE MODE',
    'UNKNOWN_COMPOUND_MODE': 'F_',
  }
}


async function getSpectra(spectraId: string): Promise<ISpectraModel> {
  const response = await fetchGet('/spectrum/id/' + spectraId);
  return await response.json() as ISpectraModel;
}


function getSpectrumTypes(): Record<string, string> {
  return {
    'H_NMR': 'H_NMR',
    'F_NMR': 'F_NMR',
    'C_NMR': 'C_NMR',
    'P_NMR': 'P_NMR',
    'B_NMR': 'B_NMR',
    'N_NMR': 'N_NMR',
    'Si_NMR': 'Si_NMR',
    'Sn_NMR': 'Sn_NMR',
    'Se_NMR': 'Se_NMR',
    'O_NMR': 'O_NMR',
    'Cl_NMR': 'Cl_NMR',
    'Al_NMR': 'Al_NMR',
    'Xe_NMR': 'Xe_NMR',
    'Li_NMR': 'Li_NMR',
    'V_NMR': 'V_NMR',
    'He_NMR': 'He_NMR',
    'Na_NMR': 'Na_NMR',
    'Mg_NMR': 'Mg_NMR',
    'K_NMR': 'K_NMR',
    'Ca_NMR': 'Ca_NMR',
    'Co_NMR': 'Co_NMR',
    'Cu_NMR': 'Cu_NMR',
    'Zn_NMR': 'Zn_NMR',
    'Br_NMR': 'Br_NMR',
    'Kr_NMR': 'Kr_NMR',
    'Rb_NMR': 'Rb_NMR',
    'Cd_NMR': 'Cd_NMR',
    'I_NMR': 'I_NMR',
    'Cs_NMR': 'Cs_NMR',
    'Pt_NMR': 'Pt_NMR',
    'Hg_NMR': 'Hg_NMR',
    'Tl_NMR': 'Tl_NMR',
    'Pb_NMR': 'Pb_NMR',
    'Sc_NMR': 'Sc_NMR',
    'Te_NMR': 'Te_NMR',
    'I_R': 'I_R',
    
  }
}

function getSpectrumOrder(): Record<string, number> {
  let orderedSpectrumTypes: Record<string, number> = {};
  Object.keys(getSpectrumTypes()).forEach((key, index) => { orderedSpectrumTypes[key] = index });
  return orderedSpectrumTypes;
}

export { getPreview, getSpectra, getSpectrumTypes, getSpectrumOrder, getReactionMixtures };
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import Typography from '@mui/material/Typography';


interface IHelpIconWithHintProps {
  title: string,
  text: string,
  showImage?: boolean,
  handleOpen: () => void
};

const HelpIconWithHint: React.FC<IHelpIconWithHintProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '6px',
    padding: '2em',
  };

  return (
    <span className='invisible-mobile'>
      <HelpOutlineSharpIcon onClick={() => { setOpen(true) }} style={{ verticalAlign: 'middle', marginLeft: '.1em'}} fontSize="large" color="primary" />
      <Modal keepMounted open={open}
        onClose={handleClose}>
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2" >
            {props.title}
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            {
              props.showImage && <img style={{ paddingTop: '2em', width: '100%'}} src='/spectrum-search-types.png' className='center-img' />
            }
            {props.text}
          </Typography>
        </Box>
      </Modal >
    </span>);
}
export default HelpIconWithHint;

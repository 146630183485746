import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';

import { Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import { fetchPost } from '../../services/GettingData';

type ILoginProps = {
  backTo: string,
  email?: string,
};

export const RestoreConfirmationPage: React.FC<ILoginProps> = (props) => {

  const [email, setEmail] = useState<string>(props.email ? props.email : '');
  const [code, setCode] = useState<string>('');
  const [passwordOne, setPasswordOne] = useState<string>('');
  const [passwordTwo, setPasswordTwo] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const history = useHistory();


  React.useEffect(() => {
    setSubmissionOpen(!!email && !!code && email.indexOf('@') > -1 && passwordOne === passwordTwo && !!passwordOne);
  }, [email, code, passwordOne, passwordTwo]);


  const showError = (errorMessage: string) => {
    setErrorDialogOpened(true);
    setErrorMessage(errorMessage);
  }


  const accountLogin = () => {
    const fetchData = async () => {
      const url = '/user/account/restore/confirm';
      const response = await fetchPost(url,
        JSON.stringify({
          'code': code,
          'email': email,
          'password': passwordOne,
        }), false);
      if (response.ok) {
        setLoading(false);
        goBack();
      }
      else {
        let detail = (await response.json())?.detail;
        showError(!!detail ? detail : 'Confirmation failed.');
      }
    }
    fetchData()
      .catch(console.error);
  }


  const onCloseErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const goBack = () => {

    history.push({ pathname: '/login', state: { backTo: '/', welcomeMessage: false, email: email } })
  }


  return (
    <Grid container className='main-frame' spacing={0}>
      <Grid container md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>

      <Header title='Restore Password Confirmation' />
      <Grid style={{ height: '2em' }} xs={12}></Grid>
      <ErrorDialog isDialogOpened={isErrorDialogOpened}
        errorMessage={errorMessage}
        warningMessage={''}
        onClose={onCloseErrorDialog} />
      <Grid xs={12} container style={{ marginTop: '3em' }}>
        <Grid xs={3}></Grid>
        <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            type="email"
            value={email}
            sx={{
              input: {
                "&::placeholder": {
                  opacity: .5,
                },
              },
              label: { color: 'blue' }
            }}
            onChange={(c) => {
              setEmail(c.target.value)
            }}
            helperText=""
            style={{ width: '100%', marginTop: '1em' }}
            placeholder='email' />
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>

      <Grid xs={12} container>
        <Grid xs={3}></Grid>
        <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            type="text"
            value={code}
            sx={{
              input: {
                "&::placeholder": {
                  opacity: .5,
                },
              },
              label: { color: 'blue' }
            }}
            onChange={(c) => { setCode(c.target.value) }}
            helperText=""
            style={{ width: '100%', marginTop: '1em' }}
            placeholder='confirmation code' />
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>

      <Grid xs={12} container>
        <Grid xs={3}></Grid>
        <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            type="password"
            inputProps={{
              autoComplete: 'new-password'
            }}
            value={passwordOne}
            sx={{
              input: {
                "&::placeholder": {
                  opacity: .5,
                },
              },
              label: { color: 'blue' }
            }}
            onChange={(c) => {
              setPasswordOne(c.target.value)
            }}
            helperText=""
            style={{ width: '100%', marginTop: '1em' }}
            placeholder='password' />
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>

      <Grid xs={12} container>
        <Grid xs={3}></Grid>
        <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            type="password"
            value={passwordTwo}
            inputProps={{
              autoComplete: 'new-password'
            }}
            sx={{
              input: {
                "&::placeholder": {
                  opacity: .5,
                },
              },
              label: { color: 'blue' }
            }}
            onChange={(c) => {
              setPasswordTwo(c.target.value)
            }}
            style={{ width: '100%', marginTop: '1em' }}
            placeholder='repeat password' />
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
        <Grid>
          <Button disabled={!isSubmissionOpen} onClick={() => accountLogin()} variant="contained">Submit</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RestoreConfirmationPage;

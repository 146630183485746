import React from 'react';
import { DropZone } from './DropZone'
import { FileList } from './FileList'

interface ComponentNameProps {
  onChange: any;
}

const DropPanel: React.FC<ComponentNameProps> = props => {
  const { } = props;
  const [isDropActive, setIsDropActive] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])

  const onDragStateChange = React.useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive)
  }, [])

  const processFile = (file: File) => {
    var reader = new FileReader();
    reader.readAsText(file);
  }


  const onFilesDrop = React.useCallback((files: File[]) => {
    setFiles(files);
    props.onChange(files);

    files.map(f => processFile(f));
  }, [])

  return (
    <div style={{fontWeight: 600}}>
      <DropZone onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop}>
        <h2>
          <span style={{fontWeight: 600}}>
            Drag zip archive with spectrum folder here.
          </span>
        </h2>
        {files.length === 0 ? (
          <span>No files to upload</span>
        ) : (
          <span>Files to upload: {files.length}</span>
        )}


        <FileList files={files} />
        <span className='spectrum-zip-type' style={{ bottom: '.3em'}}>
        <ul style={{listStyleType: 'none', paddingInlineStart: '10px' }}>
          <li>
          <span>Bruker and Varian files are supported. 1r file should be in the Bruker file folder.</span>
        </li>
        <li>
          <span>Only normal quality spectra are will be correctly processed (S/N {'>'} 10/1, flat baseline).</span>
        </li>
        </ul>
        </span>
      </DropZone>
    </div>
  )
}

export default React.memo(DropPanel)

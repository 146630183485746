export const byCompound = 'compound';
export const byPublication = 'publication';

export const intersectionByCompounds = {
    name: 'Intersection by compounds',
    method: 'intersection',
    by: byCompound};

export const intersectionByPublications = { 
        name: 'Intersection by publications',
        method: 'intersection',
        by: byPublication};

    
export const unionByCompounds = {
    name:'Union by compounds',
    method:'union',
    by: byCompound};


export const unionByPublications = {
    name:'Union by publication',
    method:'union', 
    by: byPublication};

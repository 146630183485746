import React from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';

import logo from '../img/logo.svg';
import { Item2 } from './../styles';
import AppSettings from '../API/appSettings';

function OurLogo() {
  let history = useHistory();

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Item2 className='logo-block' style={{ height: '22px', border: '10px', position: 'relative' }}>
          <a href='/main' onClick={() => {
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN);
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.MAIN);
            history.push('/main');
          }}>
             <Tooltip title='Go to the main page'>
               <img src={logo} alt="Logo" style={{ height: '120px', cursor: 'pointer' }} />
            </Tooltip>
          </a>
        </Item2>
      </Grid>
    </Grid>
  );
}
export default OurLogo;                          
  
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import TabPanel from '../components/TabPanel';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getSession } from '../services/Login';
import { fetchGet } from '../services/GettingData';
import ErrorDialog from '../components/ErrorDialog';
import Header from '../components/HeaderComponent';
import MoleculeView from '../components/MoleculeView';
import ProgressControl from '../components/ProgressControl';
import NotAuthorizedError from '../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../schemas/Exception/ExpiredAccountError';
import { ICompoundSearchModel } from '../schemas/Compound/ICompoundSearchResponse';
import { ICompoundSearchResponse } from '../schemas/Compound/ICompoundSearchResponse';
import { IPublicationSpectrumResponse } from '../schemas/Compound/ICompoundSearchResponse';
import IPublicationSearchResponse from '../schemas/Publication/IPublicationSearchResponse';
import '../App.css';
import { Item, Item2 } from '../styles';
import ISession from '../schemas/Login/ISession';

type PublicationSearchProps = { publicationId?: string};
type PublicationSearchState = {
  searchBy: number,
  urn: string,
  journal: string,
  year: string,
  page: string,
  publicationSearchResponse?: IPublicationSearchResponse,
  compoundsSearchResponse?: ICompoundSearchResponse,
  unassignedSpectrumResponse?: IPublicationSpectrumResponse,
  isFound: boolean,
  isLoading: boolean,
  isLoadingUnassignedSpectrum: boolean,
  isErrorDialogOpened: boolean,
  errorMessage: string,
  currentPage: number,
  unassignedSpectrumCurrentPage: number,
  session?: ISession,
};



class PublicationSearchPage extends React.PureComponent<PublicationSearchProps & RouteComponentProps, PublicationSearchState> {
  constructor(props: PublicationSearchProps & RouteComponentProps) {
    super(props);

    let publicationId = new URLSearchParams(props.location.search).get('publicationId');
    if (!publicationId && (props as any).location?.state?.publicationId)
      publicationId = (props as any).location?.state?.publicationId;
    
    this.state = {
      publicationSearchResponse: publicationId ? {
        id: publicationId,
        urn: '',
        journal: '',
        authors: '',
        year: '',
        title: '',
        url: ''} : undefined,
      isLoading: false,
      searchBy: 0,
      urn: '',
      journal: '',
      year: '',
      page: '0',
      isFound: false,
      isLoadingUnassignedSpectrum: false,
      isErrorDialogOpened: false,
      errorMessage: '',
      currentPage: 1,
      unassignedSpectrumCurrentPage: 1,
      session: getSession(),
    };
  }


  spectrumColumn: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      width: 0,
      hide: true
    },
    {
      field: 'spectrum_string',
      headerName: '',
      flex: 0.9,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  async componentDidMount(): Promise<void> {
    if (this.state.publicationSearchResponse?.id) {
      await this.publicationSearch();
    }
  }


  closeErrorDialog = () => {
    this.setState(prev => ({ ...prev, isErrorDialogOpened: false }))
  }


  showError = (errorMessage: string) => {
    this.setState({
      isErrorDialogOpened: true,
      isLoading: false,
      errorMessage: errorMessage
    });
  }


  header() {
    return (<div style={{ height: '2em', alignItems: 'center' }}><span></span></div>);
  }


  handleChangeSearchBy = (event: React.SyntheticEvent, newSearchBy: number) => {
    this.setState({ searchBy: newSearchBy });
  }


  handleChangeDoi = (newDoi: string) => {
    this.setState({...this.state, 
      publicationSearchResponse: undefined, 
      urn: newDoi.trim().replace('https://doi.org/', '').replace('http://doi.org/', '').toLowerCase() });
  }


  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  gotoSpectrum(spectrumId: string) {
    window.open('/spectrum/' + spectrumId, '_blank');
  }


  pageChangeHandle(event: React.ChangeEvent<unknown>, value: number) {
    this.publicationSpectraSearch(value);
  }


  unassignedSpectrumPageChangeHandle(event: React.ChangeEvent<unknown>, value: number) {
    this.unassignedSpectrumSearch(value);
  }


  async getPublicationByDoi(doi: string): Promise<IPublicationSearchResponse> {
    this.setState({
      isFound: false,
    });
    const publicationResponse = await fetchGet('/publication/urn/' + doi, true, true);
    if (!publicationResponse.ok)
      if (publicationResponse.status === 403) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
      }
      else { 
        if (publicationResponse.status === 404)
          throw 'publication ' + doi + ' not found';
        throw publicationResponse.json(); 
      }
    const publication = await publicationResponse.json();
    if (!publication)
      throw 'publication not found by doi ' + doi;
    return publication;
  }


  async getPublicationById(id: string): Promise<IPublicationSearchResponse> {
    this.setState({
      isFound: false,
    });
    const publicationResponse = await fetchGet('/publication/id/' + id, true, true);
    if (!publicationResponse.ok)
      if (publicationResponse.status === 403) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
      }
      else { 
        if (publicationResponse.status === 404)
          throw 'publication ' + id + ' not found';
        throw publicationResponse.json(); 
      }
    const publication = await publicationResponse.json();
    if (!publication)
      throw 'publication not found by id ' + id;
    return publication;
  }


  async getSpectrumByPublication(publicationId: string, spectrumCurrentPage: number): Promise<IPublicationSpectrumResponse> {
    const publicationSpectrumCompoundResponse = await fetchGet('/spectrum/search?publication_id=' + publicationId + '&null_compounds=true&page=' + spectrumCurrentPage.toString() + '&size=10', true, true);
    if (!publicationSpectrumCompoundResponse.ok)
      throw publicationSpectrumCompoundResponse.message;
    return await publicationSpectrumCompoundResponse.json();
  }


  async getCompoundsByPublication(publicationId: string, currentPage: number): Promise<ICompoundSearchResponse> {
    const publicationCompoundResponse = await fetchGet('/compound/publication/' + publicationId + '?page=' + currentPage.toString() + '&size=10', true, true);
    if (!publicationCompoundResponse.ok)
      throw publicationCompoundResponse.message;
    return await publicationCompoundResponse.json();
  }


  async publicationSearch() {
    try {
      this.setState({ isLoading: true });
      if (this.state.publicationSearchResponse?.id) {
        const publication = await this.getPublicationById(this.state.publicationSearchResponse?.id);
        this.setState(prev => ({
          ...prev,
          publicationSearchResponse: publication,
          isFound: true,
          isLoading: false,
        }), async ()=> {
          await this.publicationSpectraSearch(1);
          await this.unassignedSpectrumSearch(1)
        });
      }
      else {
        const publication = await this.getPublicationByDoi(this.state.urn);
        this.setState(prev => ({
          ...prev,
          publicationSearchResponse: publication,
          isFound: true,
          urn: this.state.urn,
          isLoading: false,
        }), async ()=> {
          await this.publicationSpectraSearch(1);
          await this.unassignedSpectrumSearch(1)
        });
      }
    }
    catch (e: any) {
      if (e instanceof ExpiredAccountError)
        (this.props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
      if (e instanceof NotAuthorizedError) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
      } else {
        this.showError(e.toString());
        this.setState(pre => ({
          ...pre,
          publicationSearchResponse: undefined,
          compoundsSearchResponse: undefined,
          isLoading: false,
        }));
      }
    }
  }


  async publicationSpectraSearch(currentPage: number) {
    try {
      this.setState({ isLoading: true });
      const compounds = await this.getCompoundsByPublication(this.state!.publicationSearchResponse!.id, currentPage);
      this.setState(prev => ({
        ...prev,
        compoundsSearchResponse: compounds,
        isFound: true,
        urn: this.state.urn,
        isLoading: false,
        currentPage: compounds.page,
      }));
    }
    catch (e: any) {
      if (e instanceof NotAuthorizedError) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
      } else {
        this.showError(e.toString());
        this.setState(pre => ({
          ...pre,
          publicationSearchResponse: undefined,
          compoundsSearchResponse: undefined,
          isFound: false,
          isLoading: false,
          currentPage: 1,
        }));
      }
    }
  }


  async unassignedSpectrumSearch(unassignedSpectrumCurrentPage: number) {
    try {
      if (!this.state.publicationSearchResponse?.id) 
        return;
      this.setState(pre=> ({ ...pre, isLoadingUnassignedSpectrum: true }));

      const spectrum = await this.getSpectrumByPublication(this.state.publicationSearchResponse?.id as string, unassignedSpectrumCurrentPage);
      this.setState(prev => ({
        ...prev,
        unassignedSpectrumResponse: spectrum,
        isFound: true,
        isLoadingUnassignedSpectrum: false,
        unassignedSpectrumCurrentPage: unassignedSpectrumCurrentPage,
      }));
    }
    catch (e: any) {
      if (e instanceof NotAuthorizedError) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
      } else {
        if (e)
          this.showError(e.toString());
      }
    }
  }


  async startSearchPublication() {
     await this.publicationSearch();
  }


  render() {
    return (
      <Grid container spacing={0} className='main-frame'>
        <ProgressControl isLoading={this.state.isLoading || this.state.isLoadingUnassignedSpectrum} />
        <Grid xs={12}>
          <Item2><div style={{ height: '2em' }}></div></Item2>
        </Grid>
        <Grid xs={12}>
        <Header title='Publication Search' showLogin={true} helpAddress='help#wips' />
        </Grid>
        <ErrorDialog isDialogOpened={this.state.isErrorDialogOpened}
          errorMessage={this.state.errorMessage}
          onClose={this.closeErrorDialog} />

          <>
            <Grid xs={12} style={{ marginTop: '4em' }}>
              <div>{this.header()}
                <Tabs value={this.state.searchBy}
                  onChange={this.handleChangeSearchBy}
                  style={{ padding: '0px' }}
                  aria-label="basic tabs">
                  <Tab label="Search by DOI" {...this.a11yProps(0)} />
                </Tabs>
                <TabPanel value={this.state.searchBy} index={0}>
                  <Item style={{ marginTop: '1em' }}>
                    <Grid container xs={12} style={{ marginTop: '2em' }} >
                      <Grid xs={1}>
                        <div style={{ marginTop: '1em' }}>
                          DOI
                        </div>
                      </Grid>
                      <Grid xs={10} md={3}>
                        <TextField
                          type="text"
                          sx={{
                            input: { "&::placeholder": { opacity: .5, }, }, label: { color: 'blue' }
                          }}
                          id="doi-query-text"
                          label=""
                          fullWidth
                          onChange={(e) => { this.handleChangeDoi(e.target.value) }}
                          defaultValue={this.state.urn}
                          helperText="ex.: 10.1021/jo502618g"
                        />
                      </Grid>
                    </Grid>
                  </Item>
                </TabPanel>
              </div>
            </Grid>
            <Grid xs={12} style={{ marginTop: '6em' }}>
              <Button disabled={!this.state.urn || this.state.isLoading} onClick={() => this.startSearchPublication()} variant="contained">Search</Button>
            </Grid>

            <Grid container md={12} xs={12}>
              {this.state.isFound &&
                <Grid md={12} xs={12}>
                  <Item id='block-stat'>
                    <div className='molecules-count'> {!this.state.isFound ? '' : <>Found publication with {this.state.compoundsSearchResponse?.total} compounds:</>}</div>
                  </Item>
                </Grid>
              }
            </Grid>
          </>

        {this.state.isFound&&!this.state.isLoading && <Grid md={12} style={{ marginTop: '2em', marginBottom: '0em' }}>
          {this.state.publicationSearchResponse && this.state.publicationSearchResponse.url}
          </Grid >
        }

 
        {this.state.isFound &&
          (this.state.isLoading ? <></> :
          (<Grid container style={{ marginTop: '2em' }}>
            {this.state.compoundsSearchResponse?.items.map((compound: ICompoundSearchModel, index: number) => {
              return (
                <Grid md={12} container spacing={0} key={index}>
                  <Grid md={3} style={{ marginBottom: '.5em', minWidth: "250px" }}>
                    <Item key={compound.id} style={{ width: "250px", marginRight: 'auto' }}>
                      <MoleculeView link={''}
                        moleculeId={compound.id}
                        isMoleculeInContainer={true}
                        svgContent={compound.svg} />
                    </Item>
                    <div>{compound.compound_representation}</div>
                  </Grid>
                  <Grid md={9}>
                    <Grid container xs={12} style={{marginLeft:'1em'}}>
                      <DataGrid
                        disableColumnMenu
                        sx={{
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                          }
                        }}
                        autoHeight
                        onCellDoubleClick={(params, event) => {
                          if (!event.ctrlKey) {
                            event.defaultMuiPrevented = true;
                          }
                          if (params.field === 'spectrum_string') this.gotoSpectrum(params.row.id);
                        }}
                        rows={compound.spectra}
                        columns={this.spectrumColumn}
                        disableSelectionOnClick
                      /></Grid>
                  </Grid>
                </Grid>)
            })}

              {!this.state.isLoading &&
                <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
                  <Pagination style={{ marginTop: '1em' }}
                    count={Math.ceil((this.state.compoundsSearchResponse ? this.state.compoundsSearchResponse.total : 0) /
                      (this.state.compoundsSearchResponse ? this.state.compoundsSearchResponse.size : 10))}
                    page={this.state.compoundsSearchResponse?.page} onChange={(e, v) => this.pageChangeHandle(e, v)} />
                </Grid>}

            </Grid>))
        }

        {this.state.isLoadingUnassignedSpectrum ? <></> :
          this.state.unassignedSpectrumResponse &&
          <Grid container style={{ marginTop: '2em', marginBottom: '2em', width: '100%' }}>
            <Grid style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <h2>This publication also contains following spectra not assigned to any compound</h2>
            </Grid>
            {
              <Grid container xs={12}>
                <DataGrid
                  sx={{
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  autoHeight
                  onCellDoubleClick={(params, event) => {
                    if (!event.ctrlKey) {
                      event.defaultMuiPrevented = true;
                    }
                    if (params.field === 'spectrum_string') this.gotoSpectrum(params.row.id);

                  }}
                  rows={this.state.unassignedSpectrumResponse?.items}
                  columns={this.spectrumColumn}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
                </Grid>
            }
            {!this.state.isLoadingUnassignedSpectrum && this.state.unassignedSpectrumResponse &&
              <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
                <Pagination style={{ marginTop: '1em' }}
                  count={Math.ceil((this.state.unassignedSpectrumResponse ? this.state.unassignedSpectrumResponse.total : 0) /
                    (this.state.unassignedSpectrumResponse ? this.state.unassignedSpectrumResponse.size : 10))}
                  page={this.state.unassignedSpectrumResponse?.page} onChange={(e, v) => this.unassignedSpectrumPageChangeHandle(e, v)} />
              </Grid>}
          </Grid>
        }
      </Grid>
    );
  }
}

export default PublicationSearchPage;

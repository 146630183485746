import AppSettings from '../API/appSettings';
import ISubSearchFilterModel from '../schemas/Compound/ISubSearchFilterModel';

function saveSubSearchFilterSettings(subSearchFilterModel: ISubSearchFilterModel) {
  localStorage.setItem('compound_props', JSON.stringify(subSearchFilterModel?.compound_props));
  localStorage.setItem('nmr_types', JSON.stringify(subSearchFilterModel?.nmr_types));
  localStorage.setItem('mol_query', JSON.stringify(subSearchFilterModel?.mol_query));
  localStorage.setItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA, subSearchFilterModel?.optional_mols[0] || '');
}
export { saveSubSearchFilterSettings };

import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';

import { Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import { fetchPost } from '../../services/GettingData';
import ErrorDialog from '../../components/ErrorDialog';

type ILoginProps = {
    location: RestorePagePS,
    backTo: string,
  };
  
  type RestorePagePS = {
    state: {
        email?: string,
    }  
}

export const RestorePage: React.FC<ILoginProps> = (props) => {
    const [email, setEmail] = useState<string>(props.location.state.email ? props.location.state.email : '');
    const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);
    const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('')

    const history = useHistory();

    React.useEffect(() => {
        setSubmissionOpen(!!email && email.indexOf('@') > -1);
    }, [email]);

    
    const showError = (errorMessage: string) => {
        setErrorDialogOpened(true);
        setErrorMessage(errorMessage);
    }

    
    const onCloseErrorDialog = () => {
        setErrorDialogOpened(false);
    }


    const accountLogin = () => {
        const fetchData = async () => {
            const url = '/user/account/restore';
            const response = await fetchPost(url,
                JSON.stringify({
                    'email': email,
                }), false);
            if (response.ok) {
                goBack();
            }
            else {
                let details = (await response.json())?.detail;
                showError(!!details ? details : 'Restoring failed.');
            }
        }
        fetchData()
            .catch(console.error);
    }


    const goBack = () => {
        history.push('/restore-confirmation');
    }


    return (
        <Grid container className='main-frame' spacing={0}>
            <Grid container md={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>

            <Header title='Restore password' />
            <Grid style={{ height: '2em' }} xs={12}></Grid>
            <ErrorDialog isDialogOpened={isErrorDialogOpened}
                errorMessage={errorMessage}
                warningMessage={''}
                onClose={onCloseErrorDialog} />

            <Grid xs={12} container style={{marginTop:'3em'}}>
                <Grid xs={3}></Grid>
                <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField className='reg-line'
                        type="email"
                        value={email}
                        sx={{
                            input: {
                                "&::placeholder": {
                                    opacity: .5,
                                },
                            },
                            label: { color: 'blue' }
                        }}
                        onChange={(c) => {
                            setEmail(c.target.value)
                        }}
                        helperText=""
                        style={{ width: '100%', marginTop: '1em' }}
                        placeholder='email' />
                </Grid>
                <Grid xs={3}></Grid>
            </Grid>


            <Grid container xs={12} style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
                <Grid>
                    <Button disabled={!isSubmissionOpen} onClick={() => accountLogin()} variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RestorePage;

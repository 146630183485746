import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import TabPanel from '../TabPanel';
import { downloadRisFile, transformJsonToAcs, 
         transformJsonToGost, transformJsonToElsevier, 
         transformJsonToWiley, transformJsonToRsc} from '../../services/Publication'; 
import { Item, Item2 } from '../../styles';
import { fetchGet } from '../../services/GettingData';
import IPublicationModel from '../../schemas/Publication/IPublicationModel';
import IPublicationPresentation from '../../schemas/Publication/IPublicationPresentation';

type IPublicationProps = { publicationId?: string, 
                           onChange?: (publicationText: string, publicationUrn: string) => void; };

export const PublicationComponent: React.FC<IPublicationProps> = (props) => {

    const [publication, setPublication] = React.useState<IPublicationModel>();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [presentations] = React.useState<IPublicationPresentation[]>([{title:'ACS', translate:transformJsonToAcs },
                                                                         {title:'Wiley', translate:transformJsonToWiley },
                                                                         {title:'Elsevier', translate:transformJsonToElsevier },
                                                                         {title:'RSC', translate:transformJsonToRsc },
                                                                         {title:'GOST', translate:transformJsonToGost },
                                                                        ]);


    React.useEffect(() => {
        const fetchData = async () => {
            if (props.publicationId) {
                const url = '/publication/id/' + props.publicationId;
                const response = await fetchGet(url);
                const json = await response.json();
                setPublication(json);
            }
        }
        fetchData()
            .catch(console.error);
    }, [props.publicationId]);

 
    const handleChange = (event: React.SyntheticEvent | undefined, newValue: number) => {
        setSelectedTab(newValue);
        let publicationText: string;
        let publicationLayout = presentations[newValue];
        publicationText = publicationLayout.translate(publication!);
 
        if (props.onChange)
            props.onChange(publicationText, publication!.urn);
    }


    React.useEffect(() => {
        if (presentations && publication)
            handleChange(undefined, 0)
    },
        [presentations, publication]);

    const panel = (presentation: IPublicationPresentation, index: number) => 
    (<TabPanel key={index} value={selectedTab} index={index}>
        <a href={publication!.url} target="_blank">
           <div style={{padding:'.5em'}} dangerouslySetInnerHTML={{__html:presentation.translate(publication!)}}></div>
        </a>
    </TabPanel>);


    const tab = (formatName: string, position: number) => (<Tab key={position} 
        style={{fontWeight: position == selectedTab ? 700 : 400}} label={formatName} {...a11yProps(position)} />);


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <Grid style={{ textAlign: 'right' }}>
            <Item style={{ marginTop: '1em', padding: '.7em' }}>
                <Tabs value={selectedTab} style={{ padding: '0px', paddingBottom:'1em' }} onChange={handleChange} aria-label="basic tabs">
                    {presentations.map((presentation: IPublicationPresentation, index: number) => { return (tab(presentation.title, index))})}
                </Tabs>
                {publication && presentations.map((presentation: IPublicationPresentation, index) => {
                    return (panel(presentation, index))
                })}
            </Item>
            <Button
                className='report-error-spectrum download-ris-file'
                onClick={() => downloadRisFile(publication)}
                variant='outlined'
                sx={{ marginTop: '1em', float: 'right', color: '#505050', borderColor: '#505050' }}
            >Download RIS</Button>
        </Grid>

    );
}
export default PublicationComponent;